import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import LinktreeList from './components/LinktreeList';
import AddLinktreeForm from './components/AddLinktreeForm';
import LinktreeDetails from './components/LinktreeDetails';
import AdminLogin from './components/AdminLogin';
import EditLinktreeForm from './components/EditLinktreeForm';
import * as api from './api/index';
import './App.css'; // Import your custom CSS for styling

const AppContent = () => {
  const [open, setOpen] = useState(false);
  const [editMode, setEditMode] = useState(false); // Manage whether we're in edit mode
  const [currentLinktree, setCurrentLinktree] = useState(null); // Track the Linktree being edited
  const location = useLocation();
  const navigate = useNavigate();

  const isAuthenticated = localStorage.getItem('isAuthenticated') === 'true';

  useEffect(() => {
    const hostname = window.location.hostname;

    // Eğer zaten doğru sayfadaysak tekrar yönlendirme yapma
    if (hostname === 'link.mbd.ae' && location.pathname === '/') {
      navigate('/linktree/66e81fabdaddf112ea8e03ef');
    } else if (hostname === 'link.mbdtravel.ae' && location.pathname === '/') {
      navigate('/linktree/66e81d30daddf112ea8e0388');
    }
  }, [location.pathname, navigate]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditMode(false); // Reset edit mode when closing
    setCurrentLinktree(null); // Reset the current Linktree being edited
  };

  const handleLogout = async () => {
    try {
      await api.logout();
      localStorage.removeItem('isAuthenticated');
      setOpen(false);
      navigate('/login');
    } catch (error) {
      console.error('Logout failed', error);
    }
  };

  const isLinktreePage = location.pathname === '/linktree';

  return (
    <div>
      {isAuthenticated && (
        <>
          <a
            className="btn-logout"
            onClick={handleLogout}
            style={{ display: isLinktreePage ? 'block' : 'none' }}
          >
            LOGOUT
          </a>

          <button
            className="btn-createLinktree"
            onClick={handleOpen}
            style={{ display: isLinktreePage ? 'block' : 'none' }}
          >
            New Linktree
          </button>
        </>
      )}

      <div className="app-container">
        <Routes>
          <Route path="/login" element={<AdminLogin />} />
          <Route path="/linktree" element={<LinktreeList />} />
          <Route path="/linktree/:id" element={<LinktreeDetails />} />
        </Routes>
      </div>

      {/* Conditionally render AddLinktreeForm or EditLinktreeForm based on edit mode */}
      {editMode && currentLinktree ? (
        <EditLinktreeForm
          linktree={currentLinktree}
          closeEditMode={handleClose}
          onLinktreeUpdate={(updatedLinktree) => setCurrentLinktree(updatedLinktree)}
        />
      ) : (
        <AddLinktreeForm open={open} handleClose={handleClose} />
      )}
    </div>
  );
};

const App = () => {
  return (
    <Router>
      <AppContent />
    </Router>
  );
};

export default App;
